<script>
import Layout from "../../layouts/main";
import TradeServiceApi from "@/service/api/trade";
import Progress from "@/service/transactionType";
import QRCode from "@/components/qrcode";
import QrCodeServiceApi from "@/service/api/qrcode";
import Spinner from "@/components/spinner";
export default {
  components: { Layout, QRCode, Spinner },
  data() {
    return {
      tradeData: null,
      fields: [
        { key: "id", sortable: false },
        { key: "giver", sortable: false },
        { key: "receiver", sortable: false },
        { key: "giver_progress", sortable: false },
        { key: "receiver_progress", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "created_at", sortable: false },
        { key: "status", sortable: false },
      ],
      progress: Progress.trade,
      tradeSelected: null,
      tradeProgress: {
        "1": "Waiting for shipment",
        "2": "Received",
        "3": "Authentic",
        "4": "Shipped",
        "5": "Fake",
      },
      pageOptions: [10, 25, 50, 100],
      receiverProgress: null,
      giverProgress: null,
      showQR: false,
      qrData: null,
      qrGenerating: false,
      rows: 0,
      filter: null,
      perPage: 10,
      currentPage: 1,
      loading: false,
    };
  },
  computed: {
    processData() {
      const item = this.tradeSelected;
      if (item) {
        const giver_size = item.trade_items.map((size) => {
          return size.giver_size;
        });
        const receiver_size = item.trade_items.map((size) => {
          return size.receiver_size;
        });

        const formattedData = {
          id: item.id,
          created_at: item.created_at,
          giver_amount: item.giver_amount,
          receiver_amount: item.receiver_amount,
          status: item.status,
          receiver_progress: item.receiver_progress,
          giver_progress: item.giver_progress,
          receiver_size: receiver_size.filter((item) => item),
          giver_size: giver_size.filter((item) => item),
        };

        return formattedData;
      } else {
        return {};
      }
    },
  },
  watch: {
    processData(val) {
      if (val) {
        this.receiverProgress = val.receiver_progress;
        this.giverProgress = val.giver_progress;
      }
    },
    perPage() {
      this.loadTradeData();
    },
  },
  mounted() {
    this.loadTradeData();
  },
  methods: {
    currentPageChange(val) {
      this.loadTradeData(val);
    },
    transactionUpdateErrorMessage() {
      this.transactionActionMessage(
        `Transaction Update`,
        "Transaction updated failed!",
        "danger"
      );
    },
    transactionUpdateSuccessMessage() {
      this.transactionActionMessage(
        `Transaction Update`,
        "Transaction updated successfully!"
      );
    },
    transactionActionMessage(title, message, variant = "success") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    receiverProgressUpdate(val) {
      this.updateTrade(
        {
          action: "edit",
          trade_id: this.tradeSelected.id,
          receiver_progress: val,
        },
        "receiver",
        this.tradeSelected.receiver_progress
      );
    },
    giverProgressUpdate(val) {
      this.updateTrade(
        {
          action: "edit",
          trade_id: this.tradeSelected.id,
          giver_progress: val,
        },
        "giver",
        this.tradeSelected.giver_progress
      );
    },
    loadTradeData(page = 1) {
      this.loading = true;
      this.processedData = [];
      this.tradeData = [];
      TradeServiceApi.getAllTradeHistory(page, this.perPage, this.filter)
        .then((response) => {
          if (response.data.success) {
            this.tradeData = response.data.trades.data;
            this.rows = response.data.trades.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateTrade(data, type, status) {
      if (type === "giver" && status !== this.giverProgress) {
        this.updateTradeAction(data, type, status);
      } else if (type === "receiver" && status !== this.receiverProgress) {
        this.updateTradeAction(data, type, status);
      }
    },
    updateTradeAction(data, type, status) {
      TradeServiceApi.updateTrade(data).then((response) => {
        if (response.data.error) {
          if (type === "giver") {
            this.giverProgress = status;
          } else {
            this.receiverProgress = status;
          }
          this.transactionUpdateErrorMessage();
        } else {
          this.transactionUpdateSuccessMessage();
          this.loadTradeData();
        }
      });
    },

    progressLabel(progress_id) {
      if (progress_id > 0 && progress_id < 6) {
        const label = this.progress[progress_id].label;
        const status = this.progress[progress_id].status;
        const color = this.progress[progress_id].color;
        let progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>
      `;

        return progress;
      }
      return `
      <div style="background-color:purple;font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">Please choose progress</div>`;
    },
    transactionClicked(val) {
      this.tradeSelected = val;
      this.$refs.TradeInProgress.show();
    },
    hideProgress() {
      this.$refs.TradeInProgress.hide();
    },
    generateQR(item, userType) {
      this.qrGenerating = true;
      this.qrData = null;
      this.showQR = true;
      this.getQRData(item.id, userType);
    },
    reloadAllData() {
      this.currentPage = 1;
      this.loadTradeData();
    },
    resetQR(value) {
      this.showQR = !value;
      this.qrGenerating = false;
    },
    async getQRData(id, userType) {
      QrCodeServiceApi.getData({
        type: "trades",
        id: id,
      })
        .then((response) => {
          if (response.data.success) {
            if (userType === 0) {
              this.qrData = response.data.giver;
            } else {
              this.qrData = response.data.receiver;
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    searchByEmail() {
      if (this.filter.length) this.loadTradeData();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card" style="min-height:800px">
          <div class="card-body">
            <div style="text-align:center;font-size:18px;font-weight:600">
              Trades History
            </div>
            <div class="row" style="padding-top:10px;">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    <button
                      @click="reloadAllData"
                      class="btn btn-sm btn-secondary"
                    >
                      <i class="ri-refresh-line"></i>
                    </button>

                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search by email"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                    <button class="btn-primary ml-1" @click="searchByEmail">
                      <i class="ri-search-line"></i>
                    </button>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <Spinner :show="loading" />
            <div
              class="table-responsive mb-0"
              v-if="tradeData && tradeData.length"
            >
              <b-table
                :items="tradeData"
                :fields="fields"
                responsive="sm"
                @row-clicked="transactionClicked"
                selectable
              >
                <template v-slot:cell(giver_progress)="row">
                  <div v-html="progressLabel(row.value)"></div>
                  <button
                    @click="generateQR(row.item, 0)"
                    class="trade-qr-btn"
                    v-if="row.value === '3' || row.value === '4'"
                  >
                    QR Code
                  </button>
                </template>
                <template v-slot:cell(receiver_progress)="row">
                  <div v-html="progressLabel(row.value)"></div>
                  <button
                    @click="generateQR(row.item, 1)"
                    class="trade-qr-btn"
                    v-if="row.value === '3' || row.value === '4'"
                  >
                    QR Code
                  </button>
                </template>
                <template v-slot:cell(created_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(updated_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(giver)="row">
                  <div>{{ row.value.name }}</div>
                  <div>{{ row.value.email }}</div>
                </template>
                <template v-slot:cell(receiver)="row">
                  <div>{{ row.value.name }}</div>
                  <div>{{ row.value.email }}</div>
                </template>
              </b-table>
            </div>
            <div class="row" v-if="tradeData && tradeData.length">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-if="tradeData && tradeData.length"
                      v-on:change="currentPageChange"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <div
              v-else
              style="text-align:center;font-size:18px;font-weight:800;margin-bottom:100px;margin-top:100px"
            >
              <span v-if="!loading">
                No Trades in Progress
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="TradeInProgress"
      id="TradeInProgress"
      hide-footer
      centered
      size="xl"
    >
      <template #modal-header>
        <div class="mx-auto">
          <h5>Trade Details</h5>
        </div>
        <div style="position:absolute;right:4px;top:4px">
          <button class="btn btn-danger" @click="hideProgress">
            X
          </button>
        </div>
      </template>
      <div class="trade-card-details">
        <div class="text-center m-2">
          {{ moment(processData.created_at).format("MMMM Do YYYY") }}
          <div>
            {{ moment(processData.created_at).format("h:mm a") }}
          </div>
        </div>
        <div class="row m-4">
          <div class="col-sm-5">
            <div v-if="processData.receiver_amount">
              <div
                style="width:100px;background-color:red;margin:auto;text-align:center;color:white;margin-bottom:5px"
              >
                PAY AED {{ processData.receiver_amount }}
              </div>
            </div>
            <b-carousel
              :id="'carousel' + processData.id"
              :interval="4000"
              indicators
              class="ml-4 mr-4"
            >
              <!-- Text slides with image -->
              <b-carousel-slide
                v-for="(giver, index) in processData.giver_size"
                :key="index"
                :img-src="giver.product.image"
              >
                <div class="trade-card-details-text">
                  <div>
                    {{ giver.product.name }}
                  </div>
                  <div style="color:gray">
                    {{ giver.size }}
                  </div>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
          <div class="col-sm-2">
            <img
              class="mt-4"
              src="@/assets/images/arrow.png"
              alt=""
              style="max-width:80px;margin:auto;display:block;padding:10px;"
            />
          </div>
          <div class="col-sm-5">
            <div v-if="processData.giver_amount">
              <div
                style="width:100px;background-color:red;margin:auto;text-align:center;color:white;margin-bottom:5px"
              >
                PAY AED {{ processData.giver_amount }}
              </div>
            </div>
            <b-carousel
              :id="'carousel-receiver' + processData.id"
              :interval="4000"
              indicators
              class="ml-4 mr-4"
            >
              <!-- Text slides with image -->
              <b-carousel-slide
                v-for="(receiver, index) in processData.receiver_size"
                :key="index"
                :img-src="receiver.product.image"
              >
                <div class="trade-card-details-text">
                  <div>
                    {{ receiver.product.name }}
                  </div>
                  <div style="color:gray">
                    {{ receiver.size }}
                  </div>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>

      <div class="row" style="margin:10px 80px;">
        <div class="col-lg-6">
          <div class="tradeGiver" v-if="tradeSelected">
            <div class="trade-user-details">
              <div class="row">
                <div style="display:inline-block;margin-left:18px;">
                  <img
                    height="85"
                    v-if="tradeSelected.giver.image"
                    :src="tradeSelected.giver.image"
                    alt=""
                    srcset=""
                    width="85"
                    class="rounded margin-top-uimage"
                  />
                  <img
                    v-else
                    height="85"
                    width="85"
                    src="@/assets/images/no-user.png"
                    alt=""
                    class="rounded margin-top-uimage"
                    srcset=""
                  />
                </div>
                <div style="margin-left:15px;margin-top:10px;">
                  <div>
                    Name: {{ tradeSelected.giver.name }}
                    <div>Email : {{ tradeSelected.giver.email }}</div>
                    <div>
                      Amount :
                      {{
                        tradeSelected.giver_amount
                          ? tradeSelected.giver_amount
                          : 0
                      }}
                    </div>
                    <div>Amount Due : {{ tradeSelected.giver_amount_due }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="margin-left:50px;">
              <FormulateInput
                v-model="giverProgress"
                :options="tradeProgress"
                type="radio"
                label="Giver Progress"
                @input="giverProgressUpdate"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="tradeReceiver" v-if="tradeSelected">
            <div class="trade-user-details">
              <div class="row">
                <div style="display:inline-block;margin-left:18px;">
                  <img
                    v-if="tradeSelected.receiver.image"
                    height="85"
                    width="85"
                    :src="tradeSelected.receiver.image"
                    alt=""
                    class="rounded margin-top-uimage"
                    srcset=""
                  />
                  <img
                    v-else
                    height="85"
                    width="85"
                    src="@/assets/images/no-user.png"
                    alt=""
                    class="rounded margin-top-uimage"
                    srcset=""
                  />
                </div>
                <div style="margin-left:15px;margin-top:10px;">
                  <div>
                    Name : {{ tradeSelected.receiver.name }}
                    <div>Email : {{ tradeSelected.receiver.email }}</div>
                    <div>
                      Amount :
                      {{
                        tradeSelected.receiver_amount
                          ? tradeSelected.receiver_amount
                          : 0
                      }}
                    </div>
                    <div>
                      Amount Due : {{ tradeSelected.receiver_amount_due }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="margin-left:50px;">
              <FormulateInput
                v-model="receiverProgress"
                :options="tradeProgress"
                type="radio"
                label="Receiver Progress"
                @input="receiverProgressUpdate"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <QRCode
      :showQR="showQR"
      :qrData="qrData"
      v-on:resetQR="resetQR"
      :qrGenerating="qrGenerating"
    />
  </Layout>
</template>

<style>
.tradeGiver {
  margin: 5px;
}

.tradeReceiver {
  margin: 5px;
}

.trade-card-details {
  transition: 0.3s;
  margin: 5px;
  padding-top: 10px;
  margin-top: -20px;
  border-radius: 30px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}
.trade-card-details-text :first-child {
  font-weight: 100;
  font-size: 14px;
}
.trade-card-details ol.carousel-indicators {
  bottom: 0px;
}
.trade-card-details .carousel-indicators li {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
}
.trade-card-details .carousel-caption {
  bottom: -95px;
  position: absolute;
  color: #000;
}

.trade-card-details .carousel-inner {
  height: 290px;
}
.trade-card-details .carousel-item img {
  height: 150px;
  width: 180px !important;
  margin: auto;
}
.tradeUserTitle {
  text-align: center;
  font-size: 16px;
  padding: 5px;
  width: 100px;
  margin: auto;
}
.trade-user-details {
  height: 120px;
}
.margin-top-uimage {
  margin-top: 8px;
}

#TradeInProgress .modal-header {
  border-bottom: none;
}
.trade-qr-btn {
  outline: none;
  border: none;
  font-size: 14px;
  margin-top: 2px;
  border-radius: 30px;
}
.trade-qr-btn:hover {
  background-color: #fff1f1;
  color: gray;
}
</style>
